<template>
    <div v-if="story.content.show_info_bar"
        ref="animationParent"
        class="relative inline-flex h-10
            items-center
            overflow-hidden whitespace-nowrap
            bg-[var(--background-color)]
            text-[0.75rem]
            text-[var(--text-color)]
            md:text-base md:font-bold"
        :class="{
            'h-12 items-center justify-center': story.content.content.length === 1,
        }"
        :style="{
            '--background-color': backgroundColor,
            '--text-color': fontColor
        }">
        <div v-if="story.content.content.length === 1"
            class="flex items-center justify-center text-wrap text-center">
            <div v-for="content in story.content.content"
                :key="content.id">
                <a v-if="content.link?.cached_url"
                    tabindex="-1"
                    :href="formatSbUrl(content.link.cached_url)">
                    <p>{{ content.text }} <v-icon icon="link"
                        class="ml-1 hidden text-[0.8rem] md:inline" /></p>
                </a>

                <p v-else>
                    {{ content.text }}
                </p>
            </div>
        </div>
        <!-- Use padding-left to make the animation start at the edge of the parent.
        The animation uses translateX which is based on the element's dimensions rather than
        the parent's, so padding is better for aligning relative to parent. -->
        <!-- Gap is used to make the separate items have about the page width's amount of distance between them. -->
        <div v-else
            class="flex items-center gap-[400px] pl-[100%] sm:gap-[500px] md:gap-[600px] lg:gap-[800px] xl:gap-[1000px]"
            :class="{
                'animate-[shimmerToLeft_var(--duration)_linear_infinite]': !!animationDuration,
            }"
            :style="{
                '--duration': `${animationDuration}s`,
            }">
            <div v-for="content in story.content.content"
                :key="content.id">
                <a v-if="content.link?.cached_url"
                    tabindex="-1"
                    :href="formatSbUrl(content.link.cached_url)">
                    <p>{{ content.text }} <v-icon icon="link"
                        class="ml-1 text-[0.8rem]" /></p>
                </a>

                <p v-else>
                    {{ content.text }}
                </p>
            </div>
        </div>

        <!-- Left / right blur -->

        <div v-if="story.content.content.length > 1"
            :style="{
                '--background-color': `${backgroundColor}`,
            }"
            class="absolute left-0 top-0 h-full w-4 bg-gradient-to-r from-[var(--background-color)] to-transparent
            md:w-2xl">
        </div>

        <div v-if="story.content.content.length > 1"
            :style="{
                '--background-color': `${backgroundColor}`,
            }"
            class="absolute right-0 top-0 h-full w-4 bg-gradient-to-r from-transparent to-[var(--background-color)]
            md:w-2xl">
        </div>
    </div>
</template>

<script setup lang="ts">
import type { ISbStoryData } from 'storyblok-js-client';
import { useWindowSize } from '@vueuse/core';

const properties = defineProps<{
    story: ISbStoryData
}>();

const { width: windowWidth } = useWindowSize();

const animationParent = ref<HTMLDivElement>();
const { format: formatSbUrl } = useSbUrl();

const animationDuration = computed(() => {
    if (!(process.client && animationParent.value)) {
        return undefined;
    }
    const width = windowWidth.value;

    const speed = 120;

    let speedMultiplier = 1;
    if (width > 640) speedMultiplier = 1.2;
    if (width > 768) speedMultiplier = 1.4;
    if (width > 1024) speedMultiplier = 1.6;
    if (width > 1280) speedMultiplier = 1.8;

    // The animation has to move 2x the parent's scroll width, we divide that by the desired speed to get the duration. We also have a speed multiplier since otherwise on wide screens the duration would get unnecessarily long and faster movement looks better there.
    return (animationParent.value.scrollWidth * 2) / (speed * speedMultiplier);
});

const backgroundColor = computed<string | undefined>(() => (
    properties.story?.content?.background_color.color === undefined
        ? '#EF1C35' : properties.story?.content?.background_color?.color));

const fontColor = computed<string | undefined>(() => (
    properties.story?.content?.font_color?.value && properties.story?.content?.font_color.value.length > 0
        ? properties.story?.content?.font_color.value
        : '#FFF'
));
</script>
